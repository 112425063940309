<template>
  <div>
    <!-- 内容区 -->
    <indexsquare
      :title="title"
      :category="classl"
      :category-show="true"
      :zlyc="zlyc"
      :type="type"
      :showflag="false"
      @listWithCategory="listWithCategory"
      @is_div_show="is_div_show"
    ></indexsquare>
  </div>
</template>
<script>
import indexsquare from '@/components/indexcom/index'
import { classlist, list } from '@/api/indexapi'
export default {
  components: {
    indexsquare
  },
  data() {
    return {
      classl: [
        { name: '全部', id: 0, select: true }
      ],
      title: '筑龙原创',
      zlyc: [],
      type: '1',
      category_id: '',
      queryId: ''
    }
  },
  mounted() {
    var banner = document.getElementById('banner')
    banner.style.display = 'block'
    this.classlistM()
    this.getlist()
  },
  methods: {
    is_div_show(val) {
      this.queryId = val
    },

    classlistM() {
      classlist().then((res) => {
        // this.classl = res.result
        var arr = res.result
        arr.forEach(e => {
          e.select = false
          this.classl.push(e)
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    listWithCategory(id) {
      this.category_id = id
      this.getlist()
    },
    getlist() {
      const id = this.$route.query.id
      const info = {
        is_more: id,
        page: 1,
        category_id: this.category_id
      }
      this.type = id
      if (id === '1') {
        this.title = '筑龙原创'
      } else {
        this.title = '京东优选'
      }
      list(info).then((res) => {
        if (this.$route.query.id == 1) {
          this.zlyc = res.result.zlProduct
        } else {
          this.zlyc = res.result.jdProduct
        }
        if (this.queryId) {
          this.zlyc.push({
            listType: 1
          })
        }
      })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.banner-box {
  border: 1px solid red;
  height: 505px;
  background: pink;
  position: relative;
  .form_line {
    position: absolute;
    left: 50%;
    margin-left: -552px;
    bottom: 10px;
    padding: 0 90px;
    .el_form {
      width: 100%;
      height: 45px;
      line-height: 45px;
      background: white;
    }
  }
}
/deep/.el-input__inner {
  border: 0px;
  border-right: 1px solid #ccc;
  border-radius: 0px;
  height: 29px;
  line-height: 29px;
  margin: 8px 0px;
}
/deep/.el-button--primary {
  height: 100%;
  border-radius: 0px;
  border: 0px;
  float: right;
}
.safe-box {
  width: 1200px;
  margin: 0 auto;
}
// 主题区域
.main-box {
  background: #fff6f7;
}
.big-list {
  width: 1200px;
  margin: 0px auto;
  padding: 20px 0px;
  h3 {
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    color: #ee2d2e;
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
    span {
      border-bottom: 2px solid #ee2d2e;
      float: left;
      height: 100%;
    }
  }
}
.small_box {
  padding: 45px 0px;
  overflow: hidden;
  .small_list {
    background: white;
    width: 255px;
    margin-right: 60px;
    margin-bottom: 45px;
    padding: 30px 30px 20px 30px;
    float: left;
    cursor: pointer;
    img {
      width: 190px;
      height: 160px;
      margin-bottom: 8px;
    }
    h4 {
      font-size: 16px;
      color: #333;
      padding: 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .small_list:nth-child(4n) {
    margin-right: 0px;
  }
  .small_count {
    line-height: 35px;
    overflow: hidden;
    img,
    span {
      float: left;
      color: #ee2d2e;
    }
    img {
      width: 20px;
      height: 15px;
      margin: 10px 0px;
      margin-right: 5px;
    }
    em {
      font-style: normal;
      float: right;
      color: #666;
      font-style: 12px;
    }
  }
}
</style>
